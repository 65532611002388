<template>
  <ClientOnly>
    <VyButton
      v-if="productCountInCart > 0"
      size="lg"
      theme="muted"
      color="primary"
      shape="rounded"
      :label="$t('go_to_cart') + ` (${productCountInCart})`"
      :to="{ name: 'biz-cart' }"
    />
    <VyButton
      v-else
      shape="rounded"
      size="lg"
      theme="muted"
      :color="disabled ? 'gray' : 'primary'"
      :disabled="disabled"
      :label="$t('buy_now')"
      @click="buyNow()"
    />
  </ClientOnly>
</template>

<script>
import { useCartStore } from "@/store/cart.js";
import { storeToRefs, mapActions } from "pinia";

export default {
  props: {
    productId: {
      type: Number,
      default: null,
    },
    isOutOfStock: Boolean,
    notAvailable: Boolean,
    max: {
      default: null,
    },
    manageInventory: Boolean,
  },

  setup() {
    const cartStore = useCartStore();
    const { productCount: productCountInCart } = storeToRefs(cartStore);
    return { productCountInCart };
  },

  computed: {
    disabled() {
      return (
        this.isOutOfStock ||
        this.notAvailable ||
        (this.manageInventory && this.maxQty <= 0)
      );
    },

    maxQty() {
      if (this.max == null || this.max == undefined) {
        return 999;
      }
      return this.max;
    },
  },

  methods: {
    ...mapActions(useCartStore, ["upsertCart"]),

    buyNow() {
      this.upsertCart({ id: this.productId }).then(() => {
        this.$vLink({
          name: "biz-cart",
        });
      });
    },
  },
};
</script>
