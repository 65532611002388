<template>
  <div>
    <!-- Top -->
    <slot name="before" :variant-media="variantData.media" />

    <!-- Attributes -->
    <ul class="space-y-3">
      <li
        v-for="(item, index) in attributes"
        :key="`attribute-${item.attribute.id}`"
      >
        <label class="text-sm text-gray-500">{{ item.attribute.name }}</label>
        <StoreProductAttributeInput
          v-model="selectedOptions[index]"
          :ui-element="item.attribute.uiElement"
          :options="item.options"
          @selectedInput="attributeChange($event, index)"
        />
      </li>
    </ul>

    <!-- Price -->
    <div class="mt-6">
      <StoreProductPrice
        :type="isSelected ? 'simple' : 'variable'"
        :value="variantData.price"
        :regular="variantData.regularPrice"
        size="lg"
      />
    </div>

    <!-- Add To Cart -->
    <ClientOnly>
      <div v-if="isSelected || isNotAvailable" class="mt-4 border-t pt-4">
        <slot
          :id="variantData.id"
          type="variant"
          :is-out-of-stock="variantData.isOutOfStock"
          :stock="variantData.stock"
          :manage-inventory="variantData.manageInventory"
          :not-available="isNotAvailable || variantData.status === 'inactive'"
          :allow-qty="variantData.allowQty"
        >
        </slot>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
export default {
  /**
   * This component helps to choose the variant.
   * The abstraction provides an easy fallback to variant values.
   * If the variant is selected, all the values from variant will be used
   * else the ones defined in the props.
   *
   * Props are defined for only those values which can be overridden in variant
   */
  props: {
    id: { type: Number, default: null },
    value: { type: Number, default: null }, // Holds the variant product id
    attributes: { type: Array, default: () => [] },
    isOutOfStock: { type: Boolean },
    media: { type: Array, default: null },
    name: { type: String, default: null },
    price: { type: Number, default: null },
    regularPrice: { type: Number, default: null },
    status: { type: String, default: null },
    variants: { type: Array, default: null },
    inModal: Boolean,
    manageInventory: { type: Boolean },
    allowQty: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      options: [],
    };
  },

  computed: {
    defaultOptions() {
      return this.attributes.map((attribute) =>
        attribute.options ? attribute.options[0]?.id : null
      );
    },

    /**
     * Default Selected Options + User Selected Options
     * This helps to select one variant by default
     */
    selectedOptions() {
      return this.defaultOptions.map((item, index) => {
        return this.options[index] == null ? item : this.options[index];
      });
    },

    isSelected() {
      return Boolean(this.selectedVariant);
    },

    /**
     * When all the attribute's options are selected
     * and still the variant is not found, its not available.
     */
    isNotAvailable() {
      return (
        this.selectedOptions.length === this.attributes?.length &&
        !this.selectedVariant
      );
    },

    /**
     * Prepares a variant object which includes variant specific data.
     * If a variant is not selected, creates an object which has fallback values from prop
     */
    variantData() {
      if (this.selectedVariant) {
        const {
          media,
          price,
          regularPrice,
          isOutOfStock,
          id,
          status,
          stock,
          manageInventory,
          allowQty,
        } = this.selectedVariant;
        return {
          // If the variant has not specific image, use the parent's default image
          media: media || this.media,
          price,
          regularPrice,
          isOutOfStock: this.isOutOfStock || isOutOfStock,
          manageInventory,
          id,
          status,
          stock,
          isNotAvailable: this.isNotAvailable,
          allowQty,
        };
      } else {
        return {
          media: this.media,
          price: this.price,
          regularPrice: this.regularPrice,
          isOutOfStock: this.isOutOfStock,
          status: "active",
          manageInventory: this.manageInventory,
          isNotAvailable: this.isNotAvailable,
        };
      }
    },

    /**
     * Finds a variant based on options selected.
     * If any option is not selected, will return empty value.
     */
    selectedVariant() {
      return this.variants?.find((variant) => {
        // Create an array of all the option ids
        const options = variant.attributes.map((item) => {
          return item.option.id;
        });

        /**
         * https://stackoverflow.com/a/40656264/3165956
         * Compares the array. If array is empty, arrays are same
         */
        const diff = options.filter((e) => !this.selectedOptions.includes(e));
        if (diff.length === 0) {
          return variant;
        }

        return null;
      });
    },
  },

  methods: {
    attributeChange(res, index) {
      this.options[index] = res;
    },
  },
};
</script>
