<template>
  <div class="product-attribute-input">
    <ul v-if="uiElement === 'radio'">
      <li v-for="option in options" :key="`option-${option.id}`">
        <VyButton
          :label="option.label"
          :color="isSelected(option) ? 'secondary' : 'gray'"
          :theme="isSelected(option) ? 'solid' : 'muted'"
          shape="rounded"
          size="md"
          :disabled="option.disabled || selected.includes(option.id)"
          @click="input($event, option)"
        />
      </li>
    </ul>

    <ul v-if="uiElement === 'color-palette'">
      <li v-for="option in options" :key="`option-${option.id}`">
        <VyButton
          :label="option.label"
          :color="isSelected(option) ? 'secondary' : 'gray'"
          :theme="isSelected(option) ? 'solid' : 'muted'"
          shape="rounded"
          size="md"
          :disabled="option.disabled || selected.includes(option.id)"
          @click="input($event, option)"
        >
          <template #icon>
            <span
              class="block w-5 h-5 border-2 border-white rounded-full me-2"
              :style="{ backgroundColor: option.value }"
            />
          </template>
        </VyButton>
      </li>
    </ul>

    <FormField v-if="uiElement === 'dropdown'">
      <FormSelect
        :options="dropdownOptions"
        :model-value="modelValue"
        @input="$emit('selectedInput', Number($event))"
      />
    </FormField>
  </div>
</template>

<script>
export default {
  props: {
    uiElement: { type: String, default: null },
    modelValue: {
      type: [Number, Array],
      default: null,
    },
    selected: {
      type: Array,
      default: () => [],
    },
    options: { type: Array, default: null },
    size: {
      type: String,
      default: "md",
    },
  },
  emits: ["input", "selectedInput"],
  computed: {
    dropdownOptions() {
      return this.options.map((option) => {
        return {
          label: option.label,
          value: option.id,
        };
      });
    },
  },
  methods: {
    isSelected(option) {
      if (this.selected.includes(option.id)) {
        return true;
      }
      if (Array.isArray(this.modelValue)) {
        return this.modelValue.includes(option.id);
      } else {
        return this.modelValue === option.id;
      }
    },
    input(value, option) {
      if (Array.isArray(this.modelValue)) {
        const newValue = [...this.modelValue];
        const index = newValue.indexOf(option.id);
        if (index > -1) {
          newValue.splice(index, 1);
        } else {
          newValue.push(option.id);
        }
        this.$emit("selectedInput", newValue);
      } else {
        this.$emit("selectedInput", option.id);
      }
    },
  },
};
</script>

<style>
.product-attribute-input {
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: -2px;
    padding: 0;
    > li {
      padding: 2px;
    }
  }
}
</style>
